import '@/boot_sentry';
import '@/boot_jquery';
import '@/boot_axios';
import '@/boot_moment';
import 'jquery-ui';
import 'bootstrap';
import 'popper.js';

import jqueryFormInit from 'jquery-form';

import '@/custom_elements';
import '@/truserve/jquery_extensions';
import '@/form_enable_update';
import '@/truserve/file_upload';

// Need to call the default export to initialize, just like with select2
// See: https://github.com/jquery-form/form/issues/618
jqueryFormInit();

/**
 * Fix problem with jQuery UI datepicker only partially working
 * in Bootstrap modal (month/year drop-downs don't work)
 *
 * Taken from
 *  https://stackoverflow.com/questions/21059598/implementing-jquery-datepicker-in-bootstrap-modal/21088713#21088713
 *
 * Doesn't help with React-Bootstrap modal though, in that case you need to add
 *
 *   enforceFocus={false}
 *
 *  as a prop to the <Modal> component
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-empty-function
$.fn.modal.Constructor.prototype.enforceFocus = () => {};
