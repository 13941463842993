import type { CustomElementOptions } from 'vue';

type CustomElementConfig = {
    component: object, // Fixme: not the best typing here
    options: CustomElementOptions,
}

type CustomElementConfigModule = {
    default: CustomElementConfig
}

type ConfigLoader = () => Promise<CustomElementConfigModule>

// Helper to ensure tag modules are using the correct config structure
export const defineCrhCustomElement = (config: CustomElementConfig) => config;


/**
 * Setup a single custom tag, but only if it's actually used on the page.
 *
 * Good for avoiding Vue unless necessary, but not good for
 * if custom-elements are injected into the page later on
 *
 * @param tagName
 * @param configLoader
 */
const registerTag = async (tagName: string, configLoader: ConfigLoader) => {
    if (!document.getElementsByTagName(tagName).length) {
        return;
    }

    const module = await configLoader();
    const { defineCustomElement } = await import('vue');

    customElements.define(
        tagName,
        defineCustomElement(
            module.default.component,
            module.default.options,
        )
    );
};


/**
 * Look for typescript files named @/tags/*.ts, and for each one
 * get the basename witout extension, and if any tags by
 * that name are present in the document, register a Vue custom
 * element for that tag using the info provided in the .ts file
 *
 */
function autoRegisterTags() {
    const tagModules = import.meta.glob([
        './tags/*.ts',
    ]);

    Object.entries(tagModules).forEach(([path, loader]) => {
        // Get the basename of the module, strip off the '.ts' extension
        //
        const tagName = path.split('/')
            .reverse()[0]
            .replace('.ts', '');

        // console.log('registering', path, 'as', tagName);
        registerTag(tagName, (loader as unknown) as ConfigLoader);
    });
}

// Go ahead and do this when the module is imported
autoRegisterTags();
